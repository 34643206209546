import {environment as rivalEnvironment} from "./environment.rival";

export const environment = {
  ...rivalEnvironment,

  rivalName: "boombet",
  casinoId: 218,
  GA_TRACKING:'G-E8NQ119Q43',

  rivalChatGroupName: "Boombet",
  prettyName: "Boombet",
  contactEmail: "support@boombetcasinos.com",
  docsEmail: "documents@boombet.email",

  publicPromotions: [
    {id: 'TRUEFORTUNE_1', name: "200", state: "eligible", details: "welcome", imgPath: "assets/brands/boombet/promo.png"},
    {id: 'TRUEFORTUNE_2', name: "cashback", state: "eligible", details: "welcome", imgPath: "assets/brands/boombet/promo.png"},
    {id: 'TRUEFORTUNE_3', name: "raffle", state: "eligible", details: "weekly", imgPath: "assets/brands/boombet/promo.png"},
  ],

  //SEO
  metaDescription: "Boombet Online Casino: premium slots (slot machine), live dealer games, blackjack, roulette, baccarat and poker. Big deposit bonuses, VIP rewards, secure mobile play and payment with 24/7 support. Fair chances, crypto payments and secure transactions are waiting for you!",
  logoName: "boombet-online-casino-logo.png",
  logoAltText: "BoomBet Casino online gambling logo with bold lettering and card suit symbols popular for high-stakes betting slots and casino bonuses."
};


